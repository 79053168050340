import { inject, observer } from "mobx-react"
import * as React from "react"
import { CredentialStore } from "../../stores/credential-store"

interface AuthenticationIndexProps {
  credentialStore?: CredentialStore
}
interface AuthenticationIndexState {}

@inject("credentialStore")
@observer
export class AuthenticationIndex extends React.Component<AuthenticationIndexProps, AuthenticationIndexState> {
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.credentialStore.authenticate()
  }

  render(): any {
    return (
      <div>
        <form className="card uk-flex uk-flex-center p-4" onSubmit={(e) => this.handleSubmit(e)}>
          <div className="uk-flex uk-flex-center uk-flex-column uk-flex-middle">
            <ul>
              <li>Please confirm you have the most up to date version of your browser.</li>
              <li>
                If you plan to use GetGranted on multiple devices, please register your smartphone as your MFA device as
                it can be used to authenticate itself and most other devices you intend to use.
              </li>
              <li>
                To successfully authenticate using a mobile device please confirm your bluetooth is turned on and that
                your device's OS is up to date.
              </li>
              <li>
                If you do not have Bluetooth or a QR enabled camera on your phone, save the passkey to your current
                device. Alternatively, you can use a hardware security key or certain mobile devices (Example: Pixel
                phone) via USB cable and selecting "USB Security Device".
              </li>
            </ul>
            <em>
              Current supported browsers are: Chrome, Safari, Edge, and Firefox on Windows (it does not work on Firefox
              on MacOS).
            </em>
            <br />
            <span className="mb-2">Please click below to continue using your authentication device</span>
            <button type="submit" className="uk-button uk-button-primary uk-width-1-2">
              Authenticate
            </button>
          </div>
        </form>
      </div>
    )
  }
}
