import * as React from 'react'
import { observer } from 'mobx-react'

import { GeneralSettingsStore } from '../../stores/general-settings-store'
import { SettingsCard } from './settings-card'
import { NewSettingsObjectForm } from './new-settings-object-form'

export interface SettingsListProps {
  generalSettingsStore?: GeneralSettingsStore
}

export interface SettingsListState {}

@observer
export class SettingsList extends React.Component<
  SettingsListProps,
  SettingsListState
> {
  renderSettingsListCards = () => {
    const { settingsObjects } = this.props.generalSettingsStore
    return settingsObjects.map(settingsObject => {
      return (
        <SettingsCard
          generalSettingsStore={this.props.generalSettingsStore}
          settingsObject={settingsObject}
          key={settingsObject.id}
        />
      )
    })
  }

  renderNewSettingsObjectForm = () => {
    return (
      <NewSettingsObjectForm
        generalSettingsStore={this.props.generalSettingsStore}
      />
    )
  }

  // string number any array or void if not returning anything
  // render(param: <param type>): string <expected return type, if html: any> {
  render() {
    return (
      <div>
        {this.renderNewSettingsObjectForm()}
        <div>{this.renderSettingsListCards()}</div>
      </div>
    )
  }
}
