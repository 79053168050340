import * as React from "react"
import { inject, observer } from "mobx-react"
import { CredentialStore } from "../../stores/credential-store"
import { CredentialForm } from "./credential-form"
import { CredentialList } from "./credential-list"

interface CredentialIndexProps {
  credentialStore?: CredentialStore
}

@inject("credentialStore")
@observer
export class CredentialIndex extends React.Component<CredentialIndexProps, {}> {
  render(): any {
    return (
      <div className="card settings-card settings-card-form">
        <p>
          Granted works towards improving security by enabling multi-factor authentication across our valued users.
          Multi-factor authentication secures your account along with your password, using a device such as the
          fingerprint reader or PIN on your smartphone or laptop.
        </p>
        <ul>
          <li>Please confirm you have the most up to date version of your browser.</li>
          <li>
            If you plan to use GetGranted on multiple devices, please register your smartphone as your MFA device as it
            can be used to authenticate itself and most other devices you intend to use.
          </li>
          <li>
            To successfully register mobile device please confirm your bluetooth is turned on and that your device's OS
            is up to date.
          </li>
          <li>
            If you do not have Bluetooth or a QR enabled camera on your phone, save the passkey to your current device.
            Alternatively, you can use a hardware security key or certain mobile devices (Example: Pixel phone) via USB
            cable and selecting "USB Security Device".
          </li>
        </ul>
        <em>
          Current supported browsers are: Chrome, Safari, Edge, and Firefox on Windows (it does not work on Firefox on
          MacOS).
        </em>
        <br />
        <br />
        <h5 className="mb-3">
          <strong>Method of Multi-Factor Authentication</strong>
        </h5>
        <CredentialForm />
        <CredentialList />
      </div>
    )
  }
}
