import { GeneralSettingsStoreModel, GENERAL_SETTING_PURPOSE_TYPE } from "./general-settings-store"
import { PurposeModel } from "../models/purpose"
import { types } from 'mobx-state-tree'

const PurposeStoreMixIn = types
  .model({})
  .props({
    generalSettingType: GENERAL_SETTING_PURPOSE_TYPE,
    settingsObjects: types.optional(types.array(PurposeModel), []),
    currentObject: types.maybe(types.reference(PurposeModel)),
    newObject: types.optional(PurposeModel, {}),
  })
  .views((self) => ({}))
  .actions((self) => ({}))

export const PurposeStoreModel = types.compose(GeneralSettingsStoreModel, PurposeStoreMixIn).named("PurposeStoreModel")
