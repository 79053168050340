import './reactotron-config'
import { Api } from './api'
import { Instance } from "mobx-state-tree"
import { createContext, useContext } from "react"
import { RootStoreModel } from '../stores/root-store'

export const setupRootStore = () => {
  const api = new Api()
  const initialState = {}
  const rootStore = RootStoreModel.create(initialState, { api })

  // debugging tools
  if (process.env.NODE_ENV == 'development') {
    //reactotron
    api.addMonitor(console.tron.apisauce)
    console.tron.trackMstNode(rootStore)
  }

  return rootStore
}

export type RootInstance = Instance<typeof RootStoreModel>

const RootStoreContext = createContext<null | RootInstance>(null)

export function useMst() {
  const store = useContext(RootStoreContext)
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider")
  }
  return store
}
