import { create, ApisauceInstance } from 'apisauce'
import humps from 'humps'

export const BASE_URL = process.env.API_BASE_URL || 'http://localhost:3000'

export class Api {
  client: ApisauceInstance

  constructor(baseURL = BASE_URL) {
    this.client = create({
      baseURL,
      headers: {
        Accept: 'application/vnd.granted.system',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        // Authorization: `Token token="${config.apiKeyToken}"`,
      },
    })

    // set camelize by default
    this.client.addResponseTransform(response => {
      response.data = humps.camelizeKeys(response.data)
    })

    this.client.addRequestTransform(request => {
      request.data = humps.decamelizeKeys(request.data, function (key, convert) {
        // Do not convert the keys expected by webauthn and created by webauthn-json
        return /^rawId|clientExtensionResults|clientDataJSON|attestationObject|authenticatorAttachment|authenticatorData|userHandle$/.test(key) ? key : convert(key)
      })
      request.params = humps.decamelizeKeys(request.params)
    })
  }

  addMonitor(monitor) {
    this.client.addMonitor(monitor)
  }

  async getIndustries() {
    return this.client.get(`/industries`)
  }

  async createIndustry(params, opts = { format: 'json' }) {
    return this.client.post(`/industries.${opts['format']}`, params)
  }

  async destroyIndustry(industryId: number, opts = { format: 'json' }) {
    return this.client.delete(`/industries/${industryId}.${opts['format']}`)
  }

  async updateIndustry(industryId: number, params, opts = { format: 'json' }) {
    return this.client.put(
      `/industries/${industryId}.${opts['format']}`,
      params
    )
  }

  async getAudiences() {
    return this.client.get(`/audiences`)
  }

  async createAudience(params, opts = { format: 'json' }) {
    return this.client.post(`/audiences.${opts['format']}`, params)
  }

  async destroyAudience(audienceId: number, opts = { format: 'json' }) {
    return this.client.delete(`/audiences/${audienceId}.${opts['format']}`)
  }

  async updateAudience(audienceId: number, params, opts = { format: 'json' }) {
    return this.client.put(`/audiences/${audienceId}.${opts['format']}`, params)
  }

  async getPurposes() {
    return this.client.get(`/purposes`)
  }

  async createPurpose(params, opts = { format: 'json' }) {
    return this.client.post(`/purposes.${opts['format']}`, params)
  }

  async destroyPurpose(purposeId: number, opts = { format: 'json' }) {
    return this.client.delete(`/purposes/${purposeId}.${opts['format']}`)
  }

  async updatePurpose(purposeId: number, params, opts = { format: 'json' }) {
    return this.client.put(`/purposes/${purposeId}.${opts['format']}`, params)
  }

  async getBusinessTypes() {
    return this.client.get(`/business_types`)
  }

  async createBusinessType(params, opts = { format: 'json' }) {
    return this.client.post(`/business_types.${opts['format']}`, params)
  }

  async destroyBusinessType(businessTypeId: number, opts = { format: 'json' }) {
    return this.client.delete(
      `/business_types/${businessTypeId}.${opts['format']}`
    )
  }

  async getSubType() {
    return this.client.get(`/sub_types`)
  }

  async createSubType(params, opts = { format: 'json' }) {
    return this.client.post(`/sub_types.${opts['format']}`, params)
  }

  async destroySubType(subTypeId: number, opts = { format: 'json' }) {
    return this.client.delete(`/sub_types/${subTypeId}.${opts['format']}`)
  }

  async updateSubType(subTypeId: number, params, opts = { format: 'json' }) {
    return this.client.put(`/sub_types/${subTypeId}.${opts['format']}`, params)
  }

  async getTags() {
    return this.client.get('/tags')
  }

  async createTag(params, opts = { format: 'json' }) {
    return this.client.post(`/tags.${opts['format']}`, params)
  }

  async updateTag(tagId: number, params, opts = { format: 'json' }) {
    return this.client.put(`/tags/${tagId}.${opts['format']}`, params)
  }

  async destroyTag(tagId: number, opts = { format: 'json' }) {
    return this.client.delete(`/tags/${tagId}.${opts['format']}`)
  }

  async createCredential(params, opts = { format: 'json' }) {
    return this.client.post(`/webauthn/credentials.${opts['format']}`, params)
  }

  async destroyCredential(credentialId, opts = { format: 'json' }) {
    return this.client.delete(`/webauthn/credentials/${credentialId}.${opts['format']}`)
  }

  async getCredentials(opts = { format: 'json' }) {
    return this.client.get(`/webauthn/credentials.${opts['format']}`)
  }

  async createChallenge(params, opts = { format: 'json' }) {
    return this.client.post(`/webauthn/challenges.${opts['format']}`, params)
  }

  async createAuthenticationsChallenge(opts = { format: 'json' }) {
    return this.client.post(`/webauthn/authentications/challenges.${opts['format']}`)
  }

  async createAuthentication(params, opts = { format: 'json' }) {
    return this.client.post(`/webauthn/authentications.${opts['format']}`, params)
  }

  async getBoutiqueForm() {
    return this.client.get('/boutique_forms')
  }

  async createBoutiqueForm(params, opts = { format: 'json' }) {
    return this.client.post(`/boutique_forms.${opts['format']}`, params)
  }

  async updateBoutiqueForm(boutiqueFormId: number, params, opts = { format: 'json' }) {
    return this.client.put(`/boutique_forms/${boutiqueFormId}.${opts['format']}`, params)
  }

  async destroyBoutiqueForm(boutiqueFormId: number, opts = { format: 'json' }) {
    return this.client.delete(`/boutique_forms/${boutiqueFormId}.${opts['format']}`)
  }

  async applyCoupon(
    couponCode: string,
    stripePlanId: string,
    opts = { format: 'json' }
  ) {
    return this.client.post(`/subscriptions/apply_coupon.${opts['format']}`, {
      coupon_code: couponCode,
      stripe_plan_id: stripePlanId,
    })
  }

  async createSubscription(opts: { [key: string]: any }) {
    return this.client.post('/subscriptions.json', opts)
  }

  async updateBusinessType(
    businessTypeId: number,
    params,
    opts = { format: 'json' }
  ) {
    return this.client.put(
      `/business_types/${businessTypeId}.${opts['format']}`,
      params
    )
  }
}
