import * as React from "react"
import { inject, observer } from "mobx-react"

import {
  GeneralSettingsStore,
  GENERAL_SETTING_INDUSTRY_TYPE,
  GENERAL_SETTING_PURPOSE_TYPE,
  GENERAL_SETTING_AUDIENCE_TYPE,
  GENERAL_SETTING_BUSINESS_TYPE,
  GENERAL_SETTING_TAG_TYPE,
  GENERAL_SETTING_SUB_TYPE,
  GENERAL_SETTING_BOUTIQUE_FORM_TYPE,
} from "../../stores/general-settings-store"
import { SettingsList } from "./settings-list"

export interface GeneralSettingsManagerProps {
  industrySettingsStore?: GeneralSettingsStore
  purposeSettingsStore?: GeneralSettingsStore
  audienceSettingsStore?: GeneralSettingsStore
  businessTypeSettingsStore?: GeneralSettingsStore
  tagSettingsStore?: GeneralSettingsStore
  subTypeSettingsStore?: GeneralSettingsStore
  boutiqueFormSettingsStore?: GeneralSettingsStore
  generalSettingType: string
}

@inject(
  "industrySettingsStore",
  "purposeSettingsStore",
  "audienceSettingsStore",
  "businessTypeSettingsStore",
  "tagSettingsStore",
  "subTypeSettingsStore",
  "boutiqueFormSettingsStore"
)
@observer
export class GeneralSettingsManager extends React.Component<GeneralSettingsManagerProps, {}> {
  determineStore = () => {
    const {
      industrySettingsStore,
      purposeSettingsStore,
      audienceSettingsStore,
      businessTypeSettingsStore,
      tagSettingsStore,
      subTypeSettingsStore,
      boutiqueFormSettingsStore,
      generalSettingType,
    } = this.props
    switch (generalSettingType) {
      case GENERAL_SETTING_INDUSTRY_TYPE:
        return industrySettingsStore
      case GENERAL_SETTING_PURPOSE_TYPE:
        return purposeSettingsStore
      case GENERAL_SETTING_AUDIENCE_TYPE:
        return audienceSettingsStore
      case GENERAL_SETTING_TAG_TYPE:
        return tagSettingsStore
      case GENERAL_SETTING_BUSINESS_TYPE:
        return businessTypeSettingsStore
      case GENERAL_SETTING_SUB_TYPE:
        return subTypeSettingsStore
      case GENERAL_SETTING_BOUTIQUE_FORM_TYPE:
        return boutiqueFormSettingsStore
      default:
        return null
    }
  }

  render() {
    const store = this.determineStore()
    return store ? <SettingsList generalSettingsStore={store} /> : null
  }
}
