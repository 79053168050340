import { GeneralSettingsStoreModel, GENERAL_SETTING_SUB_TYPE } from "./general-settings-store"
import { SubTypeModel } from "../models/sub-type"
import { types } from 'mobx-state-tree'

const SubTypeStoreMixIn = types
  .model({})
  .props({
    generalSettingType: GENERAL_SETTING_SUB_TYPE,
    settingsObjects: types.optional(types.array(SubTypeModel), []),
    currentObject: types.maybe(types.reference(SubTypeModel)),
    newObject: types.optional(SubTypeModel, {}),
  })
  .views((self) => ({}))
  .actions((self) => ({}))

export const SubTypeStoreModel = types.compose(GeneralSettingsStoreModel, SubTypeStoreMixIn).named("SubTypeStoreModel")
