import { SettingsObjectModel } from './settings-object'
import { types } from "mobx-state-tree"

const WARNING_MESSAGE =
  'This sub type cannot be removed because there are grants attached to it. Remove grants from this sub type in order to delete.'

const SubTypeMixIn = types
  .model({})
  .views((self) => ({
    get deleteWarning() {
      return WARNING_MESSAGE
    },
  }))
  .actions((self) => ({}))

export const SubTypeModel = types.compose(SettingsObjectModel, SubTypeMixIn).named("SubTypeModel")