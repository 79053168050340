import { types } from 'mobx-state-tree'
import { AudienceStoreModel } from './audience-store'
import { CredentialStoreModel } from './credential-store'
import {
  GENERAL_SETTING_INDUSTRY_TYPE,
  GENERAL_SETTING_PURPOSE_TYPE,
  GENERAL_SETTING_AUDIENCE_TYPE,
  GENERAL_SETTING_BUSINESS_TYPE,
  GENERAL_SETTING_SUB_TYPE,
  GENERAL_SETTING_TAG_TYPE,
  GENERAL_SETTING_BOUTIQUE_FORM_TYPE,
} from './general-settings-store'
import { IndustryStoreModel } from './industry-store'
import { PurposeStoreModel } from './purpose-store'
import { BusinessTypeStoreModel } from './business-type-store'
import { SubscriptionStoreModel } from './subscription-store'
import { SubTypeStoreModel } from './sub-type-store'
import { TagStoreModel } from './tag-store'
import { BoutiqueFormStoreModel } from './boutique-form-store'

export const RootStoreModel = types
  .model('RootStoreModel')
  .props({
    industrySettingsStore: types.optional(IndustryStoreModel, {
      generalSettingType: GENERAL_SETTING_INDUSTRY_TYPE,
    }),
    purposeSettingsStore: types.optional(PurposeStoreModel, {
      generalSettingType: GENERAL_SETTING_PURPOSE_TYPE,
    }),
    audienceSettingsStore: types.optional(AudienceStoreModel, {
      generalSettingType: GENERAL_SETTING_AUDIENCE_TYPE,
    }),
    businessTypeSettingsStore: types.optional(BusinessTypeStoreModel, {
      generalSettingType: GENERAL_SETTING_BUSINESS_TYPE,
    }),
    subTypeSettingsStore: types.optional(SubTypeStoreModel, {
      generalSettingType: GENERAL_SETTING_SUB_TYPE,
    }),
    tagSettingsStore: types.optional(TagStoreModel, {
      generalSettingType: GENERAL_SETTING_TAG_TYPE,
    }),
    boutiqueFormSettingsStore: types.optional(BoutiqueFormStoreModel, {
      generalSettingType: GENERAL_SETTING_BOUTIQUE_FORM_TYPE,
    }),
    subscriptionStore: types.optional(SubscriptionStoreModel, {}),
    credentialStore: types.optional(CredentialStoreModel, {}),
  })
  .actions(self => ({
    // startup: flow(function*() {
    //   //startup code goes here
    //   console.log("hello");
    // }),
    afterCreate() {
      // self.startup();
    },
  }))
