import { CredentialModel } from "../models/credential"
import { flow, types, getEnv, Instance } from "mobx-state-tree"
import { values } from "mobx"
import * as WebAuthnJSON from "@github/webauthn-json"
import * as R from 'ramda'

export const CredentialStoreModel = types
  .model({})
  .props({
    credentials: types.optional(types.array(CredentialModel), []),
    formErrors: types.optional(types.string, ""),
  })
  .views((self) => ({
    get mfaDeviceRegistered() {
      return self.credentials.length > 0
    }
  }))
  .actions((self) => ({
    createCredential: flow(function* (params) {
      const env = getEnv(self)
      const challengeResponse = yield env.api.createChallenge()

      if (challengeResponse.ok) {
        const { data } = challengeResponse
        try {
          const fromExternalDevice = yield WebAuthnJSON.create({ publicKey: data })
          const credentialParams = {...params, ...fromExternalDevice}
          const credentialResponse = yield env.api.createCredential(credentialParams)
          self.credentials.push(credentialResponse.data.credential)
          if (credentialResponse.data.redirect) window.location.replace(credentialResponse.data.redirect)
        } catch (error) {
          self.formErrors = error.message
        }
      } else {
        self.formErrors = challengeResponse.data.error
      }
    }),
    authenticate: flow(function* () {
      const env = getEnv(self)
      const challengeResponse = yield env.api.createAuthenticationsChallenge()
      if (challengeResponse.ok) {
        const { data } = challengeResponse
        try {
          const fromExternalDevice = yield WebAuthnJSON.get({ publicKey: data })
          const authenticationlResponse = yield env.api.createAuthentication(fromExternalDevice)
          window.location = authenticationlResponse.data.redirect
        } catch (error) {
          self.formErrors = error.message
        }
      } else {
        self.formErrors = challengeResponse.data.error
      }
    }),
    destroyCredential: flow(function* (id) {
      const env = getEnv(self)
      const response = yield env.api.destroyCredential(id)

      if (response.ok) {
        const { data } = response
        self.credentials = R.reject((cred) => cred.id === id, self.credentials)
      } else {
        self.formErrors = response.data.error
      }
    }),
    getCredentials: flow(function*() {
      const env = getEnv(self)

      const response = yield env.api.getCredentials()
      if (response.ok) {
        self.credentials = R.map(
          p => CredentialModel.create(p),
          response.data
        )
      } else {
        // error message
      }
    })
  }))
  .actions(self => ({
    afterCreate() {
      self.getCredentials()
    },
    generateNickname() {

      function makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      }

      return `Device ${makeid(4)}`
    }
  }))

export interface CredentialStore extends Instance<typeof CredentialStoreModel> {}
