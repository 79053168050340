/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import * as React from "react"
import * as ReactDOM from "react-dom/client"
import * as R from "ramda"

// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

import { RootStoreWrapper } from "../src/root-store-wrapper"
import { GeneralSettingsManager } from "../src/domains/admin/general-settings-manager"
import { SubscriptionForm } from "../src/domains/subscriptions/subscription-form"
import { CredentialIndex } from "../src/domains/webauthn/credential-index"
import { AuthenticationIndex } from "../src/domains/webauthn/authentication-index"

// Turbolinks.start()
ActiveStorage.start()

const renderIfElementByDataAttributeExistsWithProvider = (componentName) => {
  const selector = `[data-react-component=${componentName}]`
  const elements = document.querySelectorAll(selector)
  const components = {
    GeneralSettingsManager: GeneralSettingsManager,
    SubscriptionForm: SubscriptionForm,
    CredentialIndex: CredentialIndex,
    AuthenticationIndex: AuthenticationIndex,
  }
  if (!R.isEmpty(elements)) {
    R.forEach((el) => {
      let myProps
      if (el.dataset.reactProps) {
        try {
          myProps = JSON.parse(el.dataset.reactProps)
        } catch (e) {
          console.error(e)
          console.log(el)
          console.log(el.dataset.reactProps)
          myProps = {}
        }
      } else {
        myProps = {}
      }
      const root = ReactDOM.createRoot(el)
      root.render(<RootStoreWrapper>{React.createElement(components[componentName], myProps)}</RootStoreWrapper>)
    }, elements)
  }
}

// document.addEventListener("turbolinks:load", () => {
//@ts-ignore
$(document).ready(function () {
  setup()
})

const setup = () => {
  renderIfElementByDataAttributeExistsWithProvider("GeneralSettingsManager")
  renderIfElementByDataAttributeExistsWithProvider("SubscriptionForm")
  renderIfElementByDataAttributeExistsWithProvider("CredentialIndex")
  renderIfElementByDataAttributeExistsWithProvider("AuthenticationIndex")
}
