import { SettingsObjectModel } from './settings-object'
import { types } from 'mobx-state-tree'

const WARNING_MESSAGE = 
  'This tag could not be removed'


const TagMixIn = types
.model({})
.props({
  description: types.maybeNull(types.string)
})
.views(self => ({
  get deleteWarning() {
    return WARNING_MESSAGE
  },
  get canBeDestroyed() {
    return true
  },
  get descriptions() {
    return [{name: "tagDescription", text: "Internal Tags and Grant Genie tags are all derived from the list below. Please be aware that making a change below will affect both."}]
  }
}))
.actions(self => ({}))

export const TagModel = types.compose(SettingsObjectModel, TagMixIn).named("TagModel")
