import { GeneralSettingsStoreModel, GENERAL_SETTING_TAG_TYPE } from "./general-settings-store"
import { TagModel } from "../models/tag"
import { types } from 'mobx-state-tree'

const TagStoreMixIn = types
  .model({})
  .props({
    generalSettingType: GENERAL_SETTING_TAG_TYPE,
    settingsObjects: types.optional(types.array(TagModel), []),
    currentObject: types.maybe(types.reference(TagModel)),
    newObject: types.optional(TagModel, {}),
  })
  .views((self) => ({}))
  .actions((self) => ({}))

export const TagStoreModel = types.compose(GeneralSettingsStoreModel, TagStoreMixIn).named("TagStoreModel")
