import { inject, observer } from "mobx-react"
import * as React from "react"
import { CredentialStore } from "../../stores/credential-store"

interface CredentialFormProps {
  credentialStore?: CredentialStore
}
interface CredentialFormState {
  nickname: string
}

@inject("credentialStore")
@observer
export class CredentialForm extends React.Component<CredentialFormProps, CredentialFormState> {
  state = { nickname: ""}

  handleInputChanged = (e) => {
    this.setState((prevState) => {
      return {
        nickname: e.target.value,
      }
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState((prevState) => {
      return {
        nickname: ""
      }
    })
    this.props.credentialStore.createCredential({ nickname: this.state.nickname || this.props.credentialStore.generateNickname() })
  }

  render(): any {
    return (
      <div>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <div className="uk-flex uk-flex-left">
            <input
              className="uk-input uk-flex-auto mr-2"
              value={this.state.nickname}
              onChange={this.handleInputChanged}
              placeholder="Device nickname (optional)"
            />
            <button className="uk-button uk-button-primary mb-2 uk-flex-none" type="submit">
              Register Device
            </button>
          </div>
        </form>
      </div>
    )
  }
}
