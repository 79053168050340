import { types, flow, getEnv } from 'mobx-state-tree'
import * as R from 'ramda'

export const SubscriptionStoreModel = types
  .model('SubscriptionStoreModel')
  .props({
    couponCode: types.maybeNull(types.string),
    savingSubscription: types.maybeNull(types.boolean),
    planId: types.maybeNull(types.string),
    freeTrial: types.maybeNull(types.boolean),
    plans: types.optional(types.frozen(), []),
    amountOff: types.maybeNull(types.number),
    percentOff: types.maybeNull(types.number),
  })
  .views(self => ({
    get plan() {
      return self.planId
        ? R.find(R.propEq('id', self.planId), self.plans)
        : R.find(R.propEq('default', true), self.plans)
    },
  }))
  .views(self => ({
    get planAmount() {
      return (self.plan && self.plan.amount) || 0
    },
    get balance() {
      return self.plan && self.plan.balance != 0 && self.plan.balance
    },
    get annualPlanSavings() {
      const plan = R.find(plan => !R.isNil(plan.savings_percentage), self.plans)
      return plan && plan.savings_percentage
    },
  }))
  .views(self => ({
    get discount() {
      if (self.plan && self.amountOff) {
        return self.amountOff
      } else if (self.plan && self.percentOff) {
        return (self.percentOff * self.planAmount) / 100.0
      } else {
        return null
      }
    },
  }))
  .views(self => ({
    get subtotal() {
      const subtotal =
        self.planAmount - (self.discount || 0) + (self.balance || 0)
      return subtotal > 0 ? subtotal : 0
    },
  }))
  .views(self => ({
    get taxAmount() {
      return (self.plan && (self.subtotal * self.plan.tax_rate) / 100) || 0
    },
  }))
  .views(self => ({
    get amountDue() {
      return self.subtotal + self.taxAmount
    },
  }))
  .actions(self => ({
    hideFlashMessage() {
      const flashMessage = document.getElementById('flash-message')
      if (!R.isNil(flashMessage)) {
        flashMessage.classList.add('uk-hidden')
      }
    },
  }))
  .actions(self => ({
    resetDiscounts() {
      self.amountOff = null
      self.percentOff = null
      self.hideFlashMessage()
    },
    setCouponCode(value: string) {
      self.couponCode = value
    },
  }))
  .actions(self => ({
    setPlanId(value: string) {
      self.planId = value

      // When the user selects a different plan, reset everything related to coupons
      self.resetDiscounts()
      self.setCouponCode(null)
    },
    setPlans(plans: Array<any>) {
      self.plans = plans
    },
    applyCoupon: flow(function*() {
      const response = yield getEnv(self).api.applyCoupon(
        self.couponCode,
        self.plan.id
      )
      if (response.ok) {
        self.amountOff = response.data.amountOff
        self.percentOff = response.data.percentOff
        self.hideFlashMessage()

        return true
      } else {
        // show error message
        window.location.reload()
        window.scrollTo(0, 0)
      }
    }),
    createSubscription: flow(function*(stripe: any) {
      self.savingSubscription = true
      const { token } = yield stripe.createToken()

      if (token) {
        const response = yield getEnv(self).api.createSubscription({
          stripeToken: token.id,
          stripePlanId: self.plan.id,
          freeTrial: self.freeTrial,
          couponCode: self.couponCode,
        })

        if (response.ok) {
          window.location.pathname = '/grant-profile'
        } else {
          // reload so we see the error message set in the controller
          window.location.reload()
          window.scrollTo(0, 0)
        }
      } else {
        // error in the credit card form
        self.savingSubscription = false
      }
    }),
    // createNoPaymentInfoTrial: flow(function*(stripe: any) {
    //   self.savingSubscription = true
    //   const response = yield getEnv(self).api.createSubscription({
    //     stripePlanId: self.plan.id,
    //     freeTrial: self.freeTrial,
    //     couponCode: self.couponCode,
    //   })

    //   if (response.ok) {
    //     window.location.pathname = '/grant-profile'
    //   } else {
    //     // reload so we see the error message set in the controller
    //     window.location.reload()
    //     window.scrollTo(0, 0)
    //   }

    // }),
  }))

export type SubscriptionStore = typeof SubscriptionStoreModel.Type
