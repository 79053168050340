import { inject, observer } from "mobx-react"
import * as React from "react"
import { CredentialStore } from "../../stores/credential-store"

interface CredentialListProps {
  credentialStore?: CredentialStore
}
interface CredentialListState {
  nickname: string
}

@inject("credentialStore")
@observer
export class CredentialList extends React.Component<CredentialListProps, CredentialListState> {
  handleRemoveCredential(id) {
    this.props.credentialStore.destroyCredential(id)
  }

  render(): any {
    return (
      <div>
        {this.props.credentialStore.credentials.map((cred) => {
          return (
            <div className="uk-flex uk-flex-between uk-card uk-card-body uk-card-default p-2 mb-2" key={cred.id}>
              <div className="uk-flex">
                <span className="mr-1" uk-icon="icon: lock"></span>
                {cred.nickname}
              </div>
              <button
                className="uk-button-default uk-text-small uk-text-light uk-text-muted"
                style={{ color: "gray" }}
                onClick={() => {
                  if (window.confirm(`Are you sure you wish to delete ${cred.nickname}?`)) this.handleRemoveCredential(cred.id)
                }}
              >
                REMOVE
                <span className="ml-1" uk-icon="icon: close"></span>
              </button>
            </div>
          )
        })}
      </div>
    )
  }
}
