import { SettingsObjectModel } from './settings-object'
import { types } from "mobx-state-tree"

const WARNING_MESSAGE =
  'This business type cannot be removed because there are grants or user grant profiles attached to it. Remove grants and user grant profiles from this business type in order to delete.'

const BusinessTypeMixIn = types
  .model({})
  .views((self) => ({
    get deleteWarning() {
      return WARNING_MESSAGE
    },
  }))
  .actions((self) => ({}))

export const BusinessTypeModel = types.compose(SettingsObjectModel, BusinessTypeMixIn).named("BusinessTypeModel")