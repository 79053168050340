import * as React from 'react'

export interface SettingsCardMessageProps {
  messageText?: string
}

export interface SettingsCardMessageState {}

export class SettingsCardMessage extends React.Component<
  SettingsCardMessageProps,
  SettingsCardMessageState
> {
  render(): any {
    return (
      <div className={`settings-warning`}>
        <hr className="my-1" />
        <span className="mr-1" uk-icon="icon: warning; ratio: 0.75" />
        <span className="text-6 uk-text-danger">{this.props.messageText}</span>
      </div>
    )
  }
}
