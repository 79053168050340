import { GeneralSettingsStoreModel, GENERAL_SETTING_INDUSTRY_TYPE } from "./general-settings-store"
import { IndustryModel } from "../models/industry"
import { types } from 'mobx-state-tree'

const IndustryStoreMixIn = types
  .model({})
  .props({
    generalSettingType: GENERAL_SETTING_INDUSTRY_TYPE,
    settingsObjects: types.optional(types.array(IndustryModel), []),
    currentObject: types.maybe(types.reference(IndustryModel)),
    newObject: types.optional(IndustryModel, {}),
  })
  .views((self) => ({}))
  .actions((self) => ({}))

export const IndustryStoreModel = types.compose(GeneralSettingsStoreModel, IndustryStoreMixIn).named("IndustryStoreModel")
