import { types } from "mobx-state-tree"
import { SettingsObjectModel } from "./settings-object"

const WARNING_MESSAGE =
  "This purpose cannot be removed because there are grants attached to it. Remove grants from this purpose in order to delete."

const PurposeMixIn = types
  .model({})
  .props({
    defaultStarterFormId: types.maybeNull(types.string)
  })
  .views((self) => ({
    get deleteWarning() {
      return WARNING_MESSAGE
    },
    get additionalProperties() {
      return [{label: "Default Starter Form ID", name: "defaultStarterFormId"}]
    }
  }))
  .actions((self) => ({}))

export const PurposeModel = types.compose(SettingsObjectModel, PurposeMixIn).named("PurposeModel")
