import { SettingsObjectModel } from './settings-object'
import { types } from "mobx-state-tree"

const WARNING_MESSAGE =
  'This industry cannot be removed because there are grants attached to it. Remove grants from this industry in order to delete.'

const IndustryMixIn = types
  .model({})
  .props({
    internalName: types.maybeNull(types.string)
  })
  .views((self) => ({
    get deleteWarning() {
      return WARNING_MESSAGE
    },
    get additionalProperties() {
      return [{label: "Internal Name", name: "internalName"}]
    },
    get descriptions() {
      return [{name: "industryDescription", text: "The internal name will automatically be generated. For example, Architecture/Design -> ARCHITECTURE_DESIGN. You may edit the internal name if it requires an override."}]
    }
  }))
  .actions((self) => ({}))

export const IndustryModel = types.compose(SettingsObjectModel, IndustryMixIn).named("IndustryModel")