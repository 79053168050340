import * as React from "react"
import * as R from "ramda"
import { inject, observer } from "mobx-react"
import { Elements, StripeProvider } from "react-stripe-elements"

import { currencyFormatter } from "../../../utils/utils"
import { SubscriptionStore } from "../../stores/subscription-store"
import { StripeForm } from "./stripe-form"

export interface SubscriptionFormProps {
  subscriptionStore?: SubscriptionStore
  stripePublishableKey?: string
  plans?: { [key: string]: any }[]
  termsOfUseUrl?: string
  privacyPolicyUrl?: string
  freeTrialDays?: number
  freeTrialEnd?: string
  subscriptionBenefits?: string
}

export interface SubscriptionFormState {}

@inject("subscriptionStore")
@observer
export class SubscriptionForm extends React.Component<SubscriptionFormProps, SubscriptionFormState> {
  state: SubscriptionFormState = {}

  componentWillMount() {
    this.props.subscriptionStore.setPlans(this.props.plans)
  }

  handleUpdate = () => {}

  renderStarterFreeTrial() {
    const { subscriptionStore, freeTrialDays } = this.props
    return (
      <div className="grid-form-section--full-width xs-grid-1">
        <h4 className="grid-form-section--full-width uk-text-center">Select Payment Plan</h4>
        <label>
          <input
            className="radio-card__input js-registration-stripe-plan-toggle"
            type="radio"
            name="stripe_plan_id"
            value={subscriptionStore.plan.id}
            defaultChecked
          />
          <div className="radio-card">
            <h5 className="card__heading">{freeTrialDays} Day Free Trial to Granted Starter</h5>
            <ul className="uk-list uk-list-bullet">
              <li>Enjoy a {freeTrialDays} day free trial period</li>
              <li>After {freeTrialDays} days, you'll need to provide payment details to continue receiving service</li>
              <li>No payment details are necessary to start the trial</li>
              <li>You won't be charged automatically</li>
            </ul>
          </div>
        </label>
      </div>
    )
  }

  renderPlanOptions = () => {
    const { subscriptionStore, freeTrialDays } = this.props

    return (
      <div className={`grid-form-section--full-width xs-grid-1 md-grid-${this.props.plans.length}`}>
        <h4 className="grid-form-section--full-width uk-text-center">Select Payment Plan</h4>
        {this.props.plans.map((plan) => {
          return (
            <label className="radio-card-container grid-item--full-height" key={`plan-${plan.id}`}>
              <input
                className="radio-card__input js-registration-stripe-plan-toggle"
                type="radio"
                name="stripe_plan_id"
                value={plan.id}
                onChange={(e) => subscriptionStore.setPlanId(e.target.value)}
                checked={subscriptionStore.planId == plan.id || (R.isNil(subscriptionStore.planId) && plan.default)}
              />
              <div className="radio-card">
                <h5 className="card__heading uk-margin-small-bottom">
                  {plan.title}
                  {plan.savings_percentage && (
                    <span className="uk-badge uk-badge--brand uk-text-bold uk-margin-small-left">
                      Save {plan.savings_percentage}%
                    </span>
                  )}
                </h5>

                <div>{plan.billing_details}</div>
                <ul className="uk-list uk-list-bullet text-5">
                  {plan.notes.map((note, idx) => (
                    <li key={idx}>{note}</li>
                  ))}
                </ul>
              </div>
            </label>
          )
        })}
      </div>
    )
  }

  renderPaymentBreakdown = () => {
    const { subscriptionStore, freeTrialDays, freeTrialEnd } = this.props
    return (
      <>
        <tr>
          <td>Subscription Amount</td>
          <td className="uk-text-right">{currencyFormatter.format(subscriptionStore.planAmount)}</td>
        </tr>
        {subscriptionStore.discount && (
          <tr>
            <td>Discount</td>
            <td className="uk-text-right">-{currencyFormatter.format(subscriptionStore.discount)}</td>
          </tr>
        )}
        {subscriptionStore.balance && (
          <tr>
            <td>Balance</td>
            <td className="uk-text-right">{currencyFormatter.format(subscriptionStore.balance)}</td>
          </tr>
        )}
        <tr>
          <td>Tax</td>
          <td className="uk-text-right">{currencyFormatter.format(subscriptionStore.taxAmount)}</td>
        </tr>
        <tr>
          <td className="uk-text-bold">Total Due {freeTrialDays > 0 && ` on ${freeTrialEnd}`}</td>
          <td className="uk-text-bold uk-text-right">{currencyFormatter.format(subscriptionStore.amountDue)}</td>
        </tr>
      </>
    )
  }

  handleApplyCoupon = (e) => {
    e.preventDefault()
    this.props.subscriptionStore.applyCoupon()
  }

  render(): any {
    const { subscriptionStore, freeTrialDays, subscriptionBenefits } = this.props
    return (
      <StripeProvider apiKey={this.props.stripePublishableKey}>
        <Elements>
          <div className="login-form-container login-form-container--signup">
            <form className="registration-form login-form grid-form xs-grid-1 md-grid-2">
              {this.renderPlanOptions()}
              <div className="card card--primary grid-form-section--full-width mb-0">
                <h4>Subscription Benefits</h4>
                <div
                  className="checklist checklist--white"
                  dangerouslySetInnerHTML={{ __html: subscriptionBenefits }}
                ></div>
              </div>

              <div className="mb-3 grid-form-section--full-width uk-flex uk-flex-between uk-flex-middle">
                <input
                  className="uk-input"
                  value={subscriptionStore.couponCode || ""}
                  onChange={(e) => subscriptionStore.setCouponCode(e.target.value)}
                  placeholder="Coupon Code"
                />
                <button className="uk-button uk-button-primary uk-margin-small-left" onClick={this.handleApplyCoupon}>
                  Apply
                </button>
              </div>

              <div className="card card--grey grid-form-section--full-width mb-0 flex-centered">
                {freeTrialDays ? <h4>Total Due Now: {currencyFormatter.format(0)}</h4> : <h4>Payment Details</h4>}
                {freeTrialDays > 0 && (
                  <p>
                    After your free trial ending on {this.props.freeTrialEnd}, you will need a paid subscription. An
                    example monthly charge is shown below.
                  </p>
                )}
                <table className="uk-table uk-table-small uk-table-divider">
                  <tbody>{this.renderPaymentBreakdown()}</tbody>
                </table>
              </div>
              <StripeForm
                submit={subscriptionStore.createSubscription}
                termsOfUseUrl={this.props.termsOfUseUrl}
                privacyPolicyUrl={this.props.privacyPolicyUrl}
                freeTrialDays={freeTrialDays}
              />
            </form>
          </div>
        </Elements>
      </StripeProvider>
    )
  }
}
