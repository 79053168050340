import Reactotron from 'reactotron-react-js'
import tronsauce from 'reactotron-apisauce'
// import the plugin
import { mst } from 'reactotron-mst'

declare global {
  interface Console {
    tron: any
  }
}

if (process.env.NODE_ENV === 'production') {
  const ReactotronDummy = {
    log: props => {},
    display: props => {},
  }
  console.tron = ReactotronDummy
} else {
  Reactotron.configure({ name: 'Granted In A Box' }) // we can use plugins here -- more on this later
    .use(tronsauce())
    .use(mst())
    .connect() // let's connect!

  console.tron = Reactotron
}
