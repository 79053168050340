import { GeneralSettingsStoreModel, GENERAL_SETTING_BUSINESS_TYPE } from "./general-settings-store"
import { BusinessTypeModel } from "../models/business-type"
import { types } from 'mobx-state-tree'

const BusinesTypeStoreMixIn = types
  .model({})
  .props({
    generalSettingType: GENERAL_SETTING_BUSINESS_TYPE,
    settingsObjects: types.optional(types.array(BusinessTypeModel), []),
    currentObject: types.maybe(types.reference(BusinessTypeModel)),
    newObject: types.optional(BusinessTypeModel, {}),
  })
  .views((self) => ({}))
  .actions((self) => ({}))

export const BusinessTypeStoreModel = types.compose(GeneralSettingsStoreModel, BusinesTypeStoreMixIn).named("BusinesTypeStoreModel")
