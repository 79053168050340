import { SettingsObjectModel } from './settings-object'
import { types } from "mobx-state-tree"

const WARNING_MESSAGE =
  'This audience cannot be removed because there are grants attached to it. Remove grants from this are audience in order to delete.'

const AudienceMixIn = types
  .model({})
  .views((self) => ({
    get deleteWarning() {
      return WARNING_MESSAGE
    },
  }))
  .actions((self) => ({}))

export const AudienceModel = types.compose(SettingsObjectModel, AudienceMixIn).named("AudienceModel")