import { GeneralSettingsStoreModel, GENERAL_SETTING_BOUTIQUE_FORM_TYPE } from "./general-settings-store"
import { BoutiqueFormModel } from "../models/boutique-form"
import { types } from 'mobx-state-tree'

const BoutiqueFormStoreMixIn = types
  .model({})
  .props({
    generalSettingType: GENERAL_SETTING_BOUTIQUE_FORM_TYPE,
    settingsObjects: types.optional(types.array(BoutiqueFormModel), []),
    currentObject: types.maybe(types.reference(BoutiqueFormModel)),
    newObject: types.optional(BoutiqueFormModel, {}),
  })
  .views((self) => ({}))
  .actions((self) => ({}))

export const BoutiqueFormStoreModel = types.compose(GeneralSettingsStoreModel, BoutiqueFormStoreMixIn).named("BoutiqueFormStoreModel")
