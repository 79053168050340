import { types, applySnapshot, getSnapshot, Instance } from "mobx-state-tree"

export const CredentialModel = types
  .model("CredentialModel")
  .props({
    id: types.identifierNumber,
    nickname: types.string,
    publicKey: types.string,
    signCount: types.number
  })
  .views((self) => ({}))
  .actions((self) => ({}))

export interface Credential extends Instance<typeof CredentialModel> {}
