import { types, getEnv, flow } from 'mobx-state-tree'

import * as R from 'ramda'

import { SettingsObject, SettingsObjectModel } from '../models/settings-object'
import { PurposeModel } from '../models/purpose'
import { AudienceModel } from '../models/audience'
import { IndustryModel } from '../models/industry'
import { BusinessTypeModel } from '../models/business-type'
import { SubTypeModel } from '../models/sub-type'
import { TagModel } from '../models/tag'
import { BoutiqueFormModel } from '../models/boutique-form'

export const GENERAL_SETTING_INDUSTRY_TYPE = 'industry'
export const GENERAL_SETTING_PURPOSE_TYPE = 'purpose'
export const GENERAL_SETTING_AUDIENCE_TYPE = 'audience'
export const GENERAL_SETTING_BUSINESS_TYPE = 'businessType'
export const GENERAL_SETTING_SUB_TYPE = 'subType'
export const GENERAL_SETTING_TAG_TYPE = 'tag'
export const GENERAL_SETTING_BOUTIQUE_FORM_TYPE = 'boutiqueForm'

const GENERAL_SETTINGS_FUNCTION_MAP = {
  industry: {
    index: 'getIndustries',
    create: 'createIndustry',
    update: 'updateIndustry',
    destroy: 'destroyIndustry',
  },
  purpose: {
    index: 'getPurposes',
    create: 'createPurpose',
    update: 'updatePurpose',
    destroy: 'destroyPurpose',
  },
  audience: {
    index: 'getAudiences',
    create: 'createAudience',
    update: 'updateAudience',
    destroy: 'destroyAudience',
  },
  businessType: {
    index: 'getBusinessTypes',
    create: 'createBusinessType',
    update: 'updateBusinessType',
    destroy: 'destroyBusinessType',
  },
  subType: {
    index: 'getSubType',
    create: 'createSubType',
    update: 'updateSubType',
    destroy: 'destroySubType',
  },
  tag: {
    index: 'getTags',
    create: 'createTag',
    update: 'updateTag',
    destroy: 'destroyTag',
  },
  boutiqueForm: {
    index: 'getBoutiqueForm',
    create: 'createBoutiqueForm',
    update: 'updateBoutiqueForm',
    destroy: 'destroyBoutiqueForm',
  }
}

const SettingsObject = types.union(
  SettingsObjectModel,
  IndustryModel,
  PurposeModel,
  AudienceModel,
  BusinessTypeModel,
  SubTypeModel,
  TagModel,
  BoutiqueFormModel,
)

export const GeneralSettingsStoreModel = types
  .model('GeneralSettingsStoreModel')
  .props({
    generalSettingType: types.string,
    settingsObjects: types.optional(types.array(SettingsObject), []),
    currentObject: types.maybe(types.reference(SettingsObject)),
    newObject: types.optional(SettingsObject, {}),
    showForm: types.optional(types.boolean, false),
    formErrors: types.optional(types.string, ''),
  })
  .views(self => ({
    get currentGeneralSettingsMap() {
      return GENERAL_SETTINGS_FUNCTION_MAP[self.generalSettingType]
    },
    get formHasErrors() {
      return self.formErrors != ''
    },
    get generalSettingModel() {
      switch (self.generalSettingType) {
        case GENERAL_SETTING_INDUSTRY_TYPE:
          return IndustryModel
          break
        case GENERAL_SETTING_PURPOSE_TYPE:
          return PurposeModel
          break
        case GENERAL_SETTING_AUDIENCE_TYPE:
          return AudienceModel
          break
        case GENERAL_SETTING_BUSINESS_TYPE:
          return BusinessTypeModel
          break
        case GENERAL_SETTING_SUB_TYPE:
          return SubTypeModel
          break
        case GENERAL_SETTING_TAG_TYPE:
          return TagModel
          break
        case GENERAL_SETTING_BOUTIQUE_FORM_TYPE:
          return BoutiqueFormModel
          break
      }
    },
  }))
  .actions(self => ({
    resetForm() {
      self.showForm = false
      self.formErrors = ''
    },
    toggleForm() {
      self.showForm = !self.showForm
    },
    setCurrentObject(settingsObject: SettingsObject) {
      self.currentObject = settingsObject
    },
    resetCurrentObject() {
      self.currentObject = undefined
    },
    draftNewObject(value, fieldName = "name") {
      self.newObject[fieldName] = value
    },
    getSettingsObjects: flow(function*() {
      const env = getEnv(self)

      const response = yield env.api[self.currentGeneralSettingsMap.index]()
      if (response.ok) {
        self.settingsObjects = R.map(
          p => self.generalSettingModel.create(p),
          response.data
        )
      } else {
        // error message
      }
    }),
  }))
  .actions(self => ({
    createSettingsObject: flow(function*() {
   
      const env = getEnv(self)

      let values = {
        name: self.newObject.name,
      }
      Object.keys(self.newObject).filter(key => !R.contains(['id', 'errorMessage', 'hasGrantsAttached', 'name'], key)).forEach(propertyName => {
        values[propertyName] = self.newObject[propertyName]
      })

      const response = yield env.api[self.currentGeneralSettingsMap.create](
        values
      )

      if (response.ok) {
        const { data } = response

        self.settingsObjects.unshift(self.generalSettingModel.create(data))
        self.resetForm()
        self.newObject.reset()
      } else {
        self.formErrors = response.data.error
      }
    }),
    updateSettingsObject: flow(function*(settingsObjectClone: SettingsObject) {
      const env = getEnv(self)

      let values = {
        name: settingsObjectClone.name
      }

      Object.keys(settingsObjectClone).filter(key => !R.contains(['id', 'errorMessage', 'hasGrantsAttached', 'name'], key)).forEach(propertyName => {
        values[propertyName] = settingsObjectClone[propertyName]
      })

      //@TODO: generalize this paramater in fn updateIndustry
      const response = yield env.api[self.currentGeneralSettingsMap.update](
        settingsObjectClone.id,
        values
      )

      if (response.ok) {
        const { data } = response
        const index = self.settingsObjects.findIndex(i => i.id == data.id)
        self.settingsObjects[index] = self.generalSettingModel.create(data)
        self.resetCurrentObject()
      } else {
        self.currentObject.setErrorMessage(response.data.error)
      }
    }),
    destroySettingsObject: flow(function*(settingsObject: SettingsObject) {
      if (settingsObject.hasGrantsAttached) {
        // TODO: this block should not be hit, but setup error handling anyway?
      } else {
        const env = getEnv(self)
        const response = yield env.api[self.currentGeneralSettingsMap.destroy](
          settingsObject.id
        )
        self.settingsObjects = R.reject(
          obj => obj.id == settingsObject.id,
          self.settingsObjects
        )
      }
    }),
    afterCreate() {
      self.getSettingsObjects()
      self.newObject = self.generalSettingModel.create()
    },
  }))

export type GeneralSettingsStore = typeof GeneralSettingsStoreModel.Type
