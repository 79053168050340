import * as React from "react"
import { observer } from "mobx-react"

import { GeneralSettingsStore } from "../../stores/general-settings-store"
import { SettingsCardMessage } from "./settings-card-message"

export interface NewSettingsObjectFormProps {
  generalSettingsStore?: GeneralSettingsStore
}

export interface NewSettingsObjectFormState {}

@observer
export class NewSettingsObjectForm extends React.Component<NewSettingsObjectFormProps, NewSettingsObjectFormState> {
  state: NewSettingsObjectFormState = {}

  handleInputChanged = (value: string) => {
    this.props.generalSettingsStore.draftNewObject(value)
  }

  handleAdditionalPropertyInputChanged = (value: string, propertyName: string) => {
    this.props.generalSettingsStore.draftNewObject(value, propertyName)
  }

  handleAddButtonClicked = () => {
    const { generalSettingsStore } = this.props
    generalSettingsStore.toggleForm()
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.generalSettingsStore.createSettingsObject()
  }

  renderFormErrors = () => {
    const { formErrors } = this.props.generalSettingsStore

    return <SettingsCardMessage messageText={formErrors} />
  }

  renderNewSettingsObjectForm() {
    const { newObject, showForm, formHasErrors } = this.props.generalSettingsStore
    if (showForm) {
      return (
        <form className="card settings-card settings-card-form" onSubmit={(e) => this.handleSubmit(e)}>
          <div className="settings-card__body">
            <label className="settings-card-form__label">Name:</label>
            <input
              className="settings-card__name settings-card-form__input"
              value={newObject.name}
              onChange={(e) => this.handleInputChanged(e.target.value)}
            />
            {newObject.additionalProperties.map((property) => (
              <>
                <label key={`${property.name}-label`} className="settings-card-form__label">
                  {`${property.label}:`}
                </label>
                <input
                  className="settings-card__property settings-card-form__input"
                  key={`${property.name}-input`}
                  value={newObject[property.name]}
                  onChange={(e) => this.handleAdditionalPropertyInputChanged(e.target.value, property.name)}
                />
              </>
            ))}

            <ul className={`uk-iconnav settings-actions`}>
              <li className={`settings-actions__submit`}>
                <button type="submit" uk-icon="icon: check" onClick={(e) => this.handleSubmit(e)} />
              </li>
            </ul>
          </div>
          {formHasErrors && this.renderFormErrors()}
        </form>
      )
    } else {
      return null
    }
  }

  render(): any {
    const { newObject } = this.props.generalSettingsStore
    return (
      <div>
        <button className="uk-button uk-button-default mb-2" onClick={(e) => this.handleAddButtonClicked()}>
          Add New
        </button>
        {newObject.descriptions.map((property) => (
          <React.Fragment key={property.name}>
            {property.text && (
              <p key={property.name} style={{ paddingBottom: "15px" }}>
                {property.text}
              </p>
            )}
          </React.Fragment>
        ))}
        {this.renderNewSettingsObjectForm()}
      </div>
    )
  }
}
