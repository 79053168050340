import * as React from "react"
import { inject, observer } from "mobx-react"
import { CardElement, injectStripe } from "react-stripe-elements"
import { SubscriptionStore } from "../../stores/subscription-store"

export interface FormProps {
  subscriptionStore?: SubscriptionStore
  stripe?: any
  termsOfUseUrl?: string
  privacyPolicyUrl?: string
  freeTrialDays?: number
}

@inject("subscriptionStore")
@observer
class Form extends React.Component<FormProps, {}> {
  handleSubmit = (e) => {
    e.preventDefault()
    // if (this.props.freeTrialDays) {
    //   this.props.subscriptionStore.createNoPaymentInfoTrial(this.props.stripe)
    // } else {
      this.props.subscriptionStore.createSubscription(this.props.stripe)
    // }
  }

  render(): any {
    const { subscriptionStore } = this.props
    const buttonText = this.props.freeTrialDays ? "Start Trial" : "Pay"
    return (
      <>
        <div className="form-section grid-form-section--full-width">
          <div className="actions">
            <div id="card-errors" className="mb-1"></div>
            <div className="mb-1">{<CardElement />}</div>

            <div className="uk-text-center mb-1 uk-text-muted text-6">
              By clicking "{buttonText}", you are agreeing that you have read and accepted the{" "}
              <a href={this.props.termsOfUseUrl}>Terms of Use</a> and{" "}
              <a href={this.props.privacyPolicyUrl}>Privacy Policy</a>
            </div>

            <button
              className="uk-button button-cta uk-width-1-1 stripe-submit-button"
              onClick={this.handleSubmit}
              disabled={subscriptionStore.savingSubscription}
            >
              <div uk-spinner="ratio: 0.5" className="stripe-submit-button__spinner mr-1"></div>
              {this.props.freeTrialDays ? "Start Trial" : "Pay"}
            </button>
          </div>
        </div>
      </>
    )
  }
}

export const StripeForm = injectStripe(Form)
