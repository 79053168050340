import { SettingsObjectModel } from './settings-object'
import { types } from "mobx-state-tree"

const WARNING_MESSAGE =
  'This boutique form cannot be removed because there are grants attached to it. Remove grants from this boutique form in order to delete.'

const BoutiqueFormMixIn = types
  .model({})
  .props({
    defaultFormId: types.maybeNull(types.string)
  })
  .views((self) => ({
    get deleteWarning() {
      return WARNING_MESSAGE
    },
    get canBeDestroyed() {
      return true
    },
    get additionalProperties() {
      return [{name: "defaultFormId", label: "Default Form ID"}]
    }
  }))
  .actions((self) => ({}))

export const BoutiqueFormModel = types.compose(SettingsObjectModel, BoutiqueFormMixIn).named("BoutiqueFormModel")




